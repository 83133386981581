import React from 'react';
import styles from './TagFilter.module.css';

interface TagFilterProps {
  onSelectTag: (tag: string | null) => void;
  tags: string[];
}

const TagFilter: React.FC<TagFilterProps> = ({ onSelectTag, tags }) => {
  const handleTagClick = (tag: string | null) => {
    console.log(`Selected tag: ${tag || 'All'}`);
    onSelectTag(tag);
  };

  return (
    <div className={styles.filter}>
      <button onClick={() => handleTagClick(null)} className={styles.filterButton}>
        All
      </button>
      {tags.map(tag => (
        <button
          key={tag}
          onClick={() => handleTagClick(tag)}
          className={styles.filterButton}
        >
          {tag}
        </button>
      ))}
    </div>
  );
};

export default TagFilter;