import React from 'react';
import styles from './ImageCard.module.css';

interface Image {
  id: string;
  url: string;
  width: number;
  height: number;
  tags: string[];
}

interface ImageCardProps {
  image: Image;
}

const ImageCard: React.FC<ImageCardProps> = ({ image }) => {
  const rowSpan = Math.ceil((image.height + 40) / 10); // 40px added for tags section

  return (
    <div className={styles.card} style={{ gridRowEnd: `span ${rowSpan}` }}>
      <img 
        src={image.url} 
        alt={`${image.id}`} 
        className={styles.image}
        style={{ height: `${image.height}px` }}
      />
      <div className={styles.tags}>
        {image.tags.map((tag, index) => (
          <span key={index} className={styles.tag}>#{tag}</span>
        ))}
      </div>
    </div>
  );
};

export default ImageCard;