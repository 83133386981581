import React, { useState, useEffect, useCallback } from 'react';
import ImageCard from './ImageCard';
import styles from './ImageGallery.module.css';
import TagFilter from './TagFilter';

const tags = ['nature', 'city', 'people', 'food', 'technology'];

interface Image {
  id: string;
  url: string;
  width: number;
  height: number;
  tags: string[];
}

const ImageGallery: React.FC = () => {
  const [images, setImages] = useState<Image[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);

  const generateRandomImage = (): Image => {
    const width = Math.floor(Math.random() * 200) + 200; // Random width between 200-399
    const height = Math.floor(Math.random() * 200) + 200; // Random height between 200-399
    const randomTags = tags.sort(() => 0.5 - Math.random()).slice(0, 2); // Select 2 random tags
    return {
      id: Math.random().toString(36).substr(2, 9),
      url: `https://placehold.co/${width}x${height}`,
      width,
      height,
      tags: randomTags
    };
  };

  const loadMoreImages = useCallback(() => {
    const newImages = Array(15).fill(null).map(generateRandomImage);
    setImages(prevImages => [...prevImages, ...newImages]);
    setPage(prevPage => prevPage + 1);
    if (page > 6) setHasMore(false); // Limit to 6 pages (90 images) for this example
  }, [page]);

  useEffect(() => {
    loadMoreImages();
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 500 &&
      hasMore
    ) {
      loadMoreImages();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore]);

  const  handleTagSelect = (tag: string | null) => {
    console.log('Selected tag:', tag);
    setSelectedTag(tag);
  };

  const filteredImages = selectedTag
    ? images.filter(image => image.tags.includes(selectedTag))
    : images;

  return (
    <div className={styles.gallery}>
      <TagFilter onSelectTag={handleTagSelect}  tags={tags}/>
      <div className={styles.imageGrid}>
        {filteredImages.map(image => (
          <ImageCard key={image.id} image={image} />
        ))}
      </div>
      {!hasMore && <p>No more images to load.</p>}
    </div>
  );
};

export default ImageGallery;